/* Third party npm package imports */
import * as React from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import {toaster} from 'evergreen-ui'
import SaveIcon from '@material-ui/icons/Save'
import GetAppIcon from '@material-ui/icons/GetApp'
import CreateIcon from '@material-ui/icons/Create'
import ReplayIcon from '@material-ui/icons/Replay'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import Tooltip from '@material-ui/core/Tooltip'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import {useHotkeys} from 'react-hotkeys-hook'

/* Redux action imports */
import {
  calcAllCases,
  createProject,
  changePage,
  changeSubPage,
  saveProject,
  isShowingProjectsModal,
  updateProjectInputs,
  updateValues,
  getProjectIds,
} from '../actions'

/* HEConomics utilities imports */
import {CASE_HEADERS_INPUT_ONLY} from '../utils'
import {resetUnusedCaseInput} from '../actions/resetUnusedCaseInput'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
}))

const MainMenu = () => {
  // Use useSelector Hook to pull from Redux store
  const {cases, defaults, pricing, project, status, user, page} = useSelector(
    state => ({
      page: state.navigation.page,
      pricing: state.pricingInputs,
      project: state.projectInputs,
      cases: state.cases,
      user: state.user,
      defaults: state.defaults,
      status: state.status,
    }),
    shallowEqual,
  )

  const dispatch = useDispatch()

  const classes = useStyles()

  // useHotkeys('ctrl+enter', () => runCalcs());

  const runCalcs = () => {
    // Stale/unused case input parameters may exist on old projects, so clean them up...
    CASE_HEADERS_INPUT_ONLY.forEach(caseName =>
      dispatch(
        resetUnusedCaseInput(caseName, cases[caseName]?.prodForecastMethod),
      ),
    )
    // Send validated input to Cashflow for calculation of results
    dispatch(calcAllCases(project, cases, pricing))
  }

  const saveExistingProject = () => {
    const allProjectData = {
      Owner: user.userName,
      LastModified: moment().toISOString(),
      ProjectName: project.projectName,
      Folder: project.folder,
      Type: project.type,
      cases,
      pricingInputs: pricing,
      projectInputs: project,
    }
    console.log('about to save the project.  here it is...')
    console.log(allProjectData)
    dispatch(saveProject(allProjectData))
  }

  const showLoadProjectsModal = () => {
    dispatch(getProjectIds())
    dispatch(isShowingProjectsModal(true))
  }

  const reloadFieldDefaults = () => {
    const fieldValues = defaults.find(
      field => field.FieldName === project.field,
    )

    if (fieldValues) {
      CASE_HEADERS_INPUT_ONLY.map(item => {
        dispatch(
          updateValues(
            item,
            'opexVariableGasGross',
            fieldValues.varOpexGas?.toString(),
          ),
        )
        dispatch(
          updateValues(
            item,
            'opexPerWellGross',
            fieldValues.fixedWellCost?.toString(),
          ),
        )
        dispatch(
          updateValues(
            item,
            'overheadGross',
            fieldValues.overheadCost?.toString(),
          ),
        )
        dispatch(
          updateValues(
            item,
            'opexVariableOilGross',
            fieldValues.varOpexOil?.toString(),
          ),
        )
        dispatch(
          updateValues(item, 'nglYield', fieldValues.nglYield?.toString()),
        )
        return null
      })

      if (fieldValues.state) {
        dispatch(updateProjectInputs('state', fieldValues?.state))
      }

      // dispatch(
      //   updateProjectInputs(
      //     'workingInterestPercent',
      //     fieldValues?.workingInterestPercent?.toString() || '',
      //   ),
      // )
      // dispatch(
      //   updateProjectInputs(
      //     'netRevenueInterestPercent',
      //     fieldValues?.netRevenueInterestPercent?.toString() || '',
      //   ),
      // )
      dispatch(
        updateProjectInputs(
          'adValTaxRatePercent',
          fieldValues.adValTaxRatePercent,
        ),
      )
      dispatch(updateProjectInputs('priceDiffGas', fieldValues.priceDiffGas))
      dispatch(updateProjectInputs('priceDiffOil', fieldValues.priceDiffOil))
      dispatch(
        updateProjectInputs('nglPricePercent', fieldValues?.nglPricePercent),
      )
      dispatch(updateProjectInputs('shrinkPercent', fieldValues?.gasShrink))
    }
  }

  const createNewProject = () => {
    dispatch(createProject())
    dispatch(changePage('Case Data'))
    dispatch(changeSubPage('Cases'))
    localStorage.removeItem('localState')
  }

  const showToaster = (message, id) => {
    try {
    toaster?.success(message, {duration: 3, id})
    } catch(err) {
      console.log("Toaster error:", err);
    }
  }

  return (
    <div className="top-buttons">
      <Tooltip title="Load existing project from cloud-based storage.">
        <LowerCaseButton
          aria-label="load-project"
          color="primary"
          size="small"
          className={classes.button}
          startIcon={<GetAppIcon />}
          onClick={showLoadProjectsModal}
        >
          Load
        </LowerCaseButton>
      </Tooltip>
      <Tooltip title="Start a new project with empty input fields.  If you previously saved an existing project that is similar, it may be more efficient to load it, then rename and update it.  Also, on the Setting page, you can define a default project that is loaded when you open the app.">
        <LowerCaseButton
          aria-label="create-project"
          color="primary"
          size="small"
          className={classes.button}
          startIcon={<CreateIcon />}
          onClick={createNewProject}
        >
          New
        </LowerCaseButton>
      </Tooltip>
      <Tooltip title="Save project to cloud-based storage.  It is identified by the Project Name and your user name.">
        <LowerCaseButton
          aria-label="save-project"
          color="primary"
          size="small"
          className={classes.button}
          startIcon={<SaveIcon />}
          onClick={saveExistingProject}
        >
          Save
        </LowerCaseButton>
      </Tooltip>
      <Tooltip title="Reload field defaults.  The orange input boxes contain default values that are used in our corporate reserve report.  They are provided as a convenience, and can be overwritten.  The default values are also reloaded each time you select a new Field from the drop-down menu in the project info box.">
        <LowerCaseButton
          color="primary"
          size="small"
          className={classes.button}
          startIcon={<ReplayIcon />}
          onClick={reloadFieldDefaults}
        >
          Reload
        </LowerCaseButton>
      </Tooltip>
      <Tooltip title="Calculate cashflow & economic metrics.  Update plots.">
        <LowerCaseButton
          aria-label="calculate-cashflow"
          color="primary"
          size="small"
          startIcon={<DoubleArrowIcon />}
          onClick={runCalcs}
        >
          Calculate
        </LowerCaseButton>
      </Tooltip>
      {/*status.showCalcSuccess &&
        showToaster('Cashflow calculation complete.', 'calc-complete')*/}
      {status.showSaveSuccess && showToaster('Project Saved.', 'project-saved')}
    </div>
  )
}

const LowerCaseButton = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: 'transparent',
    color: 'white',
    minHeight: '40px',
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
  },
})(Button)

export default MainMenu
