import {updateValues} from '.'
import {CaseNameType, ProductionForecastMethodType} from '../utils'

/**
 * When the production forecast method changes, we delete unused or calculated
 * inputs.  Otherwise, inappropriate/stale values will appear in some views.
 * For example, if the user enters a hyperbolic exponent, followed by a
 * production forecast method change to exponential, then I need to remove the
 * hyperbolic exponent so that it does not appear on the print view (b/c it is
 * stale and does not belong with an exponential forecast).
 *
 * @author Keith Elliott, 9/3/2021
 */
export const resetUnusedCaseInput = (
  caseName: CaseNameType,
  productionForecastMethod: ProductionForecastMethodType,
) => async dispatch => {
  if (
    productionForecastMethod === 'Exponential: Calc Reserves and Final Rate'
  ) {
    dispatch(updateValues(caseName, 'reserves', ''))
    dispatch(updateValues(caseName, 'finalDailyRate', ''))
    dispatch(updateValues(caseName, 'hyperbolicExponent', ''))
    dispatch(
      updateValues(caseName, 'terminalAnnualEffectiveDeclineRatePercent', ''),
    )
  } else if (
    productionForecastMethod === 'Hyperbolic: Calc Reserves and Final Rate'
  ) {
    dispatch(updateValues(caseName, 'reserves', ''))
    dispatch(updateValues(caseName, 'finalDailyRate', ''))
    dispatch(
      updateValues(caseName, 'terminalAnnualEffectiveDeclineRatePercent', ''),
    )
  } else if (
    productionForecastMethod ===
    'Hyperbolic to Exponential: Calc Reserves and Final Rate'
  ) {
    dispatch(updateValues(caseName, 'reserves', ''))
    dispatch(updateValues(caseName, 'finalDailyRate', ''))
  } else if (
    productionForecastMethod === 'Exponential: Calc Decline and Life'
  ) {
    dispatch(updateValues(caseName, 'annualEffectiveDeclineRatePercent', ''))
    dispatch(updateValues(caseName, 'maxLifeYears', ''))
    dispatch(updateValues(caseName, 'hyperbolicExponent', ''))
    dispatch(
      updateValues(caseName, 'terminalAnnualEffectiveDeclineRatePercent', ''),
    )
  } else if (productionForecastMethod === 'Custom Major Phase Forecast') {
    dispatch(updateValues(caseName, 'initialDailyRate', ''))
    dispatch(updateValues(caseName, 'annualEffectiveDeclineRatePercent', ''))
    dispatch(updateValues(caseName, 'hyperbolicExponent', ''))
    dispatch(
      updateValues(caseName, 'terminalAnnualEffectiveDeclineRatePercent', ''),
    )
    dispatch(updateValues(caseName, 'finalDailyRate', ''))
    dispatch(updateValues(caseName, 'maxLifeYears', ''))
    dispatch(updateValues(caseName, 'reserves', ''))
  } else {
    throw new Error(
      'Invalid production forecast method name.  Update the provided value, ' +
        'or update ProductionForecastMethodType.  The provided value equals: ' +
        productionForecastMethod,
    )
  }
}
