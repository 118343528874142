import React, {useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import SettingsIcon from '@material-ui/icons/Settings'
import Tooltip from '@material-ui/core/Tooltip'

import {
  changeNavigationArray,
  changePage,
  changeSubPage,
  toggleAdmin,
  getProjectIds,
} from '../actions'

export const USER_SETTINGS_NAVIGATION_ARRAY = [
  'My Projects',
  'Custom Price Forecasts',
]

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
}))

const SettingsButton = () => {
  const [
    userSettingsNavigationArray,
    setUserSettingsNavigationArray,
  ] = useState(USER_SETTINGS_NAVIGATION_ARRAY)
  // const [caseData, setCaseData] = useState(['Cases', 'Additional Investments']);

  // Use useSelector Hook to pull from Redux store
  const {status} = useSelector(
    state => ({
      // navigation: state.navigation,
      status: state.status,
    }),
    shallowEqual,
  )

  const dispatch = useDispatch()

  const classes = useStyles()

  // const showUserSettingsScreen = () => {
  //   if (navigation.page !== 'User') {
  //     dispatch(changePage('User'))
  //     dispatch(changeSubPage('My Projects'))
  //     dispatch(changeNavigationArray(user))
  //   } else {
  //     dispatch(changePage('Case Data'))
  //     dispatch(changeSubPage('Cases'))
  //     dispatch(changeNavigationArray(caseData))
  //   }

  //   if (status.showAdmin) {
  //     dispatch(toggleAdmin())
  //   }
  // }

  const showUserSettingsScreen = () => {
    dispatch(getProjectIds())
    dispatch(changePage('User'))
    dispatch(changeSubPage('My Projects'))
    dispatch(changeNavigationArray(userSettingsNavigationArray))

    // if (status.showAdmin) {
    //   dispatch(toggleAdmin())
    // }
  }

  return (
    <Tooltip
      title={
        <div>
          <p>Settings options include:</p>
          <p>Define a default project that is loaded when you open the app.</p>
          <p>Define a custom price forecast.</p>
        </div>
      }
    >
      <LowerCaseButton
        aria-label="settings-button"
        color="primary"
        size="small"
        className={classes.button}
        startIcon={<SettingsIcon />}
        onClick={showUserSettingsScreen}
      >
        Settings
      </LowerCaseButton>
    </Tooltip>
  )
}

const LowerCaseButton = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: 'transparent',
    color: 'white',
    minHeight: '30px',
    marginBottom: '0',
    marginTop: '0',
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
  },
})(Button)

export default SettingsButton
