import React, {useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import Tooltip from '@material-ui/core/Tooltip'

import {
  // toggleAdmin,
  changeNavigationArray,
  changePage,
  changeSubPage,
  getProjectIds,
} from '../actions'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
}))

const AdminButton = () => {
  const [admin, setAdmin] = useState([
    'Diffs & OPEX',
    'Taxes',
    'Prices',
    'Global Settings',
    'Users',
  ])
  const [caseData, setCaseData] = useState(['Cases', 'Additional Investments'])

  // Use useSelector Hook to pull from Redux store
  const {status} = useSelector(
    state => ({
      status: state.status,
    }),
    shallowEqual,
  )

  const dispatch = useDispatch()

  const classes = useStyles()

  // const toggleAdminScreen = () => {
  //   if (!status.showAdmin) {
  //     dispatch(changePage('Admin'));
  //     dispatch(changeSubPage('Diffs & OPEX'));
  //     dispatch(changeNavigationArray(admin));
  //   } else {
  //     dispatch(changePage('Case Data'));
  //     dispatch(changeSubPage('Cases'));
  //     dispatch(changeNavigationArray(caseData));
  //   }
  //   dispatch(toggleAdmin());
  // };

  const showAdminScreen = () => {
    dispatch(getProjectIds())
    dispatch(changePage('Admin'))
    dispatch(changeSubPage('Diffs & OPEX'))
    dispatch(changeNavigationArray(admin))
    // dispatch(toggleAdmin())
  }

  return (
    <Tooltip title="Manage default values & price forecasts, delete projects, etc.">
      <LowerCaseButton
        color="primary"
        size="small"
        className={classes.button}
        startIcon={<SupervisorAccountIcon />}
        onClick={showAdminScreen}
      >
        Admin
      </LowerCaseButton>
    </Tooltip>
  )
}

const LowerCaseButton = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: 'transparent',
    color: 'white',
    minHeight: '30px',
    marginBottom: '0',
    marginTop: '0',
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
  },
})(Button)

export default AdminButton
