import * as React from 'react';
import { connect } from 'react-redux';

const Footer = () => (
    <footer>
      {` `}
    </footer>
);

export default Footer;
