import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Alert, AlertTitle } from '@material-ui/lab';

const BrowserMessage = () => {
  const { validBrowser, loggedIn } = useSelector((state) => ({
    validBrowser: state.status.validBrowser,
    loggedIn: state.status.loggedIn,
  }), shallowEqual);

  return (
    <>
      {!validBrowser && loggedIn && <Alert severity="warning">This application is currently only supported in Google Chrome. Please consider switching browsers for the full experience. </Alert>}
    </>
  );
};

export default BrowserMessage;
