import React from 'react'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

/* Component-specific style sheet imports */
import '../styles/helpButton.css'

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}))

export default function HelpButton() {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div style={{ color: 'white' }}>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <HelpOutlineIcon fontSize="small" color="inherit" />
      </Typography>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div style={{ maxWidth: '600px' }}>
          <p>
            Welcome to HEConomics - an app that facilitates efficient and
            consistent investment analysis
          </p>

          <p>
            <b>Brief Instructions:</b> Enter appropriate values in the yellow
            and orange shaded input boxes, then hit Calculate at the top-left of
            the screen to calculate cashflow and economic metrics. Browse
            through the various tabs (Reserves, Revenue, etc) to observe the
            calculated output.
          </p>

          <p>
            <b>Background:</b> At Hilcorp we adhere to a probabilistic style of
            investment analysis. Because we deal with risky and uncertain
            projects, we define a chance of success (Ps), and a range of
            outcomes (high, median & low). Hence, the app accepts input (lots of
            input!) for your project and the range of outcomes.
          </p>

          <p>
            To facilitate input, default values are provided in the
            orange-shaded input boxes. The Reserves Team maintains the defaults
            – they are the values used in the corporate reserve report. Update
            them as you see fit.
          </p>

          <p>
            The yellow-shaded input boxes accept values that are specific to
            your analysis.
          </p>

          <p>
            You can define a default project via the app Settings. I recommend
            that you fine-tune a project, and set it as your default. Then, with
            future projects, update the Project Name and other input fields as
            needed.
          </p>

          <p>
            We will steadily add training resources over time. In the meantime,
            if you need an introduction, ask me, Natalie Brent, or a teammate
            that already uses the tool.
          </p>

          <p>
            Finally, please let me know if you have questions, experience bugs
            or desire new features. The Reserves Team and Dev Team are here to
            help you thrive!
          </p>

          <p>
            Keith Elliott, 9/19/2020 (office: 907-777-8355, cell: 832-233-5855)
          </p>
          <p>App Version 2023-07-14 (currency format bug fix update)</p>

          {/*

            <b>Yellow Inputs:</b>
            {` Non-default inputs`}
          </p>
          <p>
            <b>Orange Inputs:</b>
            {` Controlled by field selected. These inputs will be overwritten when Field changes or when 'Reload' is selected.
            These values may be overwritten with custom values.`}
          </p>
          <p>HEConomics Version 2020-09-19</p>
      */}
          {/* <h4>Keyboard Shortcuts</h4>
          <ul>
            <li>Ctrl + Enter: Run Cashflow Calculations</li>
          </ul> */}
        </div>
      </Popover>
    </div>
  )
}
