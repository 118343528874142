/* NPM module imports */
import * as React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import HelpButton from './HelpButton';

import AdminButton from './AdminButton';
import SettingsButton from './SettingsButton';
import MainMenu from './MainMenu';
import '../styles/header.css';

const Header = ({ siteTitle }) => {
  const {
    loggedIn,
    user,
    page,
    isOffline,
    settings,
  } = useSelector((state) => ({
    loggedIn: state.status.loggedIn,
    page: state.navigation.page,
    user: state.user,
    isOffline: state.status.isOffline,
    settings: state.settings,
  }),
  shallowEqual);

  const { name, userName, role } = user || { name: '', userName: '', role: 'User' };

  if (page === 'Print View' || !loggedIn) {
    return <></>;
  }

  return (
    <header
      className="header"
    >
      <MainMenu />
      <div
        className="site-title"
      >
        <Link
          to="/"
          style={{
            color: 'white',
            textDecoration: 'none',
          }}
        >
          {loggedIn && siteTitle}
        </Link>
      </div>
      <div style={{ color: 'white', fontSize: '14px' }}>
        {isOffline && 'OFFLINE'}
      </div>
      <div className="header-details">
        <div className="header-name">
          {loggedIn && name}
        </div>
        {loggedIn && <HelpButton />}
        {loggedIn && <SettingsButton />}
        {loggedIn && role === 'Administrator' && <AdminButton />}
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
