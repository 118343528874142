/* NPM module imports */
import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import Footer from './Footer';
import BrowserMessage from './BrowserMessage';
import './layout.css';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  // Use useSelector Hook to pull from Redux store
  const { status } = useSelector((state) => ({
    status: state.status,
  }), shallowEqual);

  return (
    <>
      {!status.validBrowser && <BrowserMessage />}
      {status.loggedIn && <Header siteTitle={data.site.siteMetadata.title} />}
      <div
        style={{
          margin: '0 auto',
        }}
      >
        <main>{children}</main>
        {status.loggedIn && <Footer />}
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
